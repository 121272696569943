
import { defineComponent, PropType } from 'vue'
import Select from "@/components/Select.vue";
import PRODUCT, {newProduct} from "@/interfaces/product";
import USER, {newUser} from "@/interfaces/user";
import CATEGORY, {newCategorie} from "@/interfaces/category";
import apiService from "@/api/api";

export default defineComponent({
  name: "Form",
  // components: { Select },
  props: {
    show: {type: Boolean, default: false},
    authForm: {type: Boolean, default: false},
    action: {type: String, default: ''},
    tab: {type: String, required: true},
    tabKey: {type: String, default: ''},
    data: {type: Object as PropType<PRODUCT | CATEGORY | USER> | any, required: true}
  },
  data() {
    const formData: PRODUCT | CATEGORY | USER | any = {}
    const formImages: any[] = []
    const screenImages: any[] = []
    const categories: any[] = []
    const statusData: any[] = [
        {value: 0, text: 'Возврату или обмену не подлежит'},{value: 1, text: 'Подлежит возврату'},
      {value: 2, text: 'Подлежит обмену'},{value: 3, text: 'Подлежит возврату и обмену'}
    ]
    return {
      statusData,
      formData,
      formImages,
      screenImages,
      categories,
      categorySelectOpened: false,
      statusSelectOpened: false,
      editedImageIndex: -1
      // show: false
    }
  },
  watch: {
    show: function() {
      // console.log('show ', this.show, this.newData())
      this.formImages = []
      this.screenImages = []
      if (this.show) this.formData = this.newData()
      else this.formData = {}
    },
    // setTimeout(() => {
    //   this.show = true
    // }, 1000)
  },
  computed: {
    title(): string {
      // console.log(this)
      if (this.authForm) return 'Авторизация'
      let title = this.action === 'add' ? 'Добавить ' : this.action === 'edit' ? 'Редактировать ' : 'Удалить '
      // console.log(this.tab, JSON.stringify(this.formData))
      switch (this.tab) {
        case 'categories':
          return title + 'категорию' + (this.action !== 'del' ? '' : ` ${this.formData.name}?`)
        case 'products':
          return title + 'товар' + (this.action !== 'del' ? '' : ` ${this.formData.id}?`)
        case 'users':
          return title + 'пользователя' + (this.action !== 'del' ? '' : ` ${this.formData.name}?`)
        default:
          return title + ''
      }
    },
    button(): string {
      if (this.authForm) return 'Войти'
      switch (this.action) {
        case 'add':
          return 'Добавить'
        case 'edit':
          return 'Редактировать'
        case 'del':
          return 'Удалить'
        default:
          return ''
      }
    }
  },
  methods: {
    actionIs(action: string): boolean {
      return action === this.action
    },
    tabIs(tab: string): boolean {
      return this.tab === tab
    },
    statusText(): string {
      if (this.formData.status != null) {
        for (let i = 0; i < this.statusData.length; i++) {
          if (this.statusData[i].value === this.formData.status) {
            return this.statusData[i].text
          }
        }
      }
      return 'Статус не установлен'
    },
    formHasImagesToSend(): boolean {
      if (!this.formImages.length) return false
      for (let i = 0; i < this.formImages.length; i++) {
        if (this.formImages[i] != null) return true
      }
      return false
    },
    imgSrc(image: string): string {
      if (image.length > 24) return image
      return apiService.getSrc(image, this.tabKey)
    },
    newData():  PRODUCT | CATEGORY | USER | any {
      // console.log('newdata: ', this.tab)
      switch (this.tab) {
        case 'categories':
          if (this.action === 'add') return newCategorie()
          else {
            if (this.data.image) {
              this.screenImages = [this.data.image]
              this.formImages = [undefined]
            }
            return newCategorie(this.data)
          }
        case 'products':
          apiService.get('c').then((r: any): any => {
            if (!r.data.error) this.categories = r.data.categories || []
          })
          if (this.action === 'add') {
            return newProduct()
          } else {
            if (this.data.images) {
              this.screenImages = this.data.images.split(',')
              this.formImages[this.screenImages.length - 1] = undefined
              // this.formImages.fill(undefined, 0, this.screenImages.length)
              console.log(JSON.stringify(this.screenImages),JSON.stringify(this.formImages))
            }
            return newProduct(this.data)
          }
        case 'users':
          if (this.action === 'add') return newUser()
          else return newUser(this.data)
        default:
          return {email: '', password: ''}
      }
    },
    closeForm() {
      this.$emit('close')
    },
    confirmAction() {
      if (this.actionIs('add') && (this.tabIs('products') || this.tabIs('categories')) && !this.formImages.length) {
        this.$emit('notify', {type: 'w', text: 'Добавьте изображение'})
        return
      }
      const obj: any = {action: this.action, data: null}
      switch (this.action) {
        case 'add':
          if ((this.tabIs('products') || this.tabIs('categories')) && !this.formImages.length) {
            this.$emit('notify', {type: 'w', text: 'Добавьте изображение'})
            return
          }
          if (this.formData.price != null) this.formData.price = +this.formData.price
          obj.data = this.formData
          if (this.tabIs('products')) obj.images = this.formImages
          else obj.image = this.formImages[0]
          break
        case 'edit': {
          let keys = Object.keys(this.formData)
          if (keys.length === 0) return
          if (this.formData.price != null) this.formData.price = +this.formData.price
          if (this.formData.quantity != null) this.formData.quantity = +this.formData.quantity
          if (this.formData.discount != null) {
            this.formData.discount = +this.formData.discount
            if (this.formData.discount === 0 && this.data.discount > 0) this.formData.discount = -1
          }
          let editedData: any = {}
          for (let i = 0; i < keys.length; i++) {
            if (keys[i] === 'image' || keys[i] === 'images') editedData[keys[i]] = this.formData[keys[i]]
            if (this.data[keys[i]] !== this.formData[keys[i]]) editedData[keys[i]] = this.formData[keys[i]]
            if (i === keys.length - 1) {
              let length = Object.keys(editedData).length
              if (length <= 1 && (length === 0 ? true : editedData.images != null || editedData.image != null) && !this.formHasImagesToSend()) return
              editedData.id = this.data.id
              obj.data = editedData
            }
          }
          if (this.tabIs('products')) {
            obj.images = this.formHasImagesToSend() ? this.formImages : undefined
            if (obj.images) obj.data.images = this.data.images
          } else {
            obj.image = this.formHasImagesToSend() ? this.formImages[0] : undefined
            if (obj.image) obj.data.image = this.data.image
          }
          break
        }
        // case 'del':
        //   obj.data = this.formData
        //   break
        default:
          obj.data = this.formData
      }
      // alert(JSON.stringify(obj))
      this.$emit('submit', obj)
    },
    clickToEditImage(index: number) {
      console.log('click to edit', index)
      this.editedImageIndex = index
      const input: any = this.$refs['img-edit'] as HTMLInputElement
      // console.log('input ', input, input.click())
      if (input) input.click()
      else this.editedImageIndex = -1
    },
    onFileInput(event: any) {
      if (event && event.target && event.target.files && event.target.files.length) {
        this.$nextTick().then(() => {
          if(this.actionIs('add')) {
            this.formImages = []
            this.screenImages = []
          }
          const files = event.target.files
          for (let i = 0; i < files.length; i++) {
            let reader = new FileReader()
            reader.onload = (function (index: number, images: Array<string>, actionIs: (action: string) => boolean) {
              return function (e: any) {
                if(actionIs('add')) images.push(e.target.result)
                else images.splice(index, 1, e.target.result)
              }
            })(this.editedImageIndex, this.screenImages, this.actionIs);
            if(this.actionIs('add')) this.formImages.push(event.target.files[i])
            else this.formImages.splice(this.editedImageIndex, 1, event.target.files[i])
            reader.readAsDataURL(event.target.files[i])
          }
        });
      }
    },
    onNumberInput(e: any, checkForDiscount?: boolean) {
      console.log(e.keyCode)
      if (e && e.keyCode && ((e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8 || e.keyCode === 46)) {
        if (checkForDiscount) {
          console.log(this.formData.discount)
          if (+this.formData.discount <= 100) {
            this.formData.discount = +this.formData.discount
            return
          } else {
            this.formData.discount = 100
          }
        } else {
          this.formData.price = +this.formData.price
          this.formData.quantity = +this.formData.quantity
          return
        }
      }
      console.log('preventing')
      e.preventDefault()
    },
    clickOnSelect(key: string) {
      switch (key) {
        case 'cat':
          this.categorySelectOpened = !this.categorySelectOpened
          this.$nextTick().then(() => {
            const sel: any = this.$refs['cat-sel']
            if (!this.categorySelectOpened) sel.removeAttribute('style')
            else sel.setAttribute('style', `bottom: ${-sel.scrollHeight - 2}px`)
          })
          break
        case 'status':
          this.statusSelectOpened = !this.statusSelectOpened
          this.$nextTick().then(() => {
            const sel: any = this.$refs['status-sel']
            if (!this.statusSelectOpened) sel.removeAttribute('style')
            else sel.setAttribute('style', `bottom: ${-sel.scrollHeight - 2}px`)
          })
          break
        default:
      }
    },
    onSelect(value: string | number, key?: string) {
      console.log('on select: ', value, key)
      if (!key) {
        if (this.formData.name != null) {
          value = value + ''
          let arr: string[] = this.formData.name.split(',')
          const index = arr.indexOf(value)
          if (index > -1) {
            arr.splice(index, 1)
            this.formData.name = arr.join(',')
          } else {
            if (this.formData.name !== '') this.formData.name += ','
            this.formData.name += value
          }
        }
      } else {
        console.log(this.formData[key] != null)
        if (this.formData[key] != null) this.formData[key] = value
      }
      console.log(JSON.stringify(this.formData))
    },
    optionValueChosen(value: string | number, key?: string): boolean {
      if (key != null && key !== '') {
        if (this.formData[key] != null) return +this.formData[key] === +value
        else return false
      } else return this.formData.name.indexOf(value) > -1
    },

  }
})
