export default interface PRODUCT {
    id?: number,
    name: string,
    description?: string,
    images: string,
    ratio?: string,
    price: number,
    views?: number,
    quantity?: number,
    discount: number,
    status: number,
    status_expires?: number,
    freeShipping?: boolean,
}

export function newProduct(options?: Partial<PRODUCT>): PRODUCT {
    const defaults = {
        name: '',
        images: '',
        price: 0,
        quantity: 0,
        discount: 0,
        views: 0,
        status: -1
    }
    return {
        ...defaults,
        ...options,
    }
}
