export default interface USER {
    id: number,
    role?: number,
    session_expires?: number,
    reg_date: number,
    uuid: string,
    name: string,
    login: string,
    password: string,
    session_token?: string,
    avatar?: string,
}

export function newUser(options?: Partial<USER>): USER {
    const defaults = {
        id: 0,
        reg_date: 0,
        uuid: '',
        name: '',
        login: '',
        password: ''
    }
    return {
        ...defaults,
        ...options
    }
}
