
import { defineComponent } from 'vue';
import {mapActions} from "vuex";
import {sha256} from "js-sha256";
import apiService from "@/api/api";
import Form from "@/components/Form.vue";

export default defineComponent({
  name: 'Auth',
  components: { Form },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  // beforeMount() {
  //   console.log(this.$store.getters.AUTHORIZED)
  // },
  methods: {
    ...mapActions([
       'notify',

    ]),
    logIn(event: any) {
      console.log(JSON.stringify(event))
      // if (process.env.NODE_ENV === 'development') {
      //   this.$router.replace('/console')
      //   return
      // }
      if (!event.data) return
      if (!event.data.email || event.data.email === '') {
        this.notify({type: 'w', text: 'Введите логин'})
        return
      } else if (!event.data.password || event.data.password === '') {
        this.notify({type: 'w', text: 'Введите пароль'})
        return
      }
      const hash = sha256.create().update(event.data.password).hex();
      apiService.logIn({
        email: event.data.email,
        password: hash
      }).then((response: any): any => {
        console.log(JSON.stringify(response.data));
        if (response.data.error) {
          this.notify({type: 'd', text: 'Ошибка авторизации'})
          return;
        }
        const user = response.data.user
        if (user) {
          localStorage.setItem('jsa_sTok', user.session_token)
          localStorage.setItem('jsa_sExp', user.session_expires)
          this.$store.commit('user', user)
          if (response.data.menu) {
            this.$store.commit('menu', response.data.menu)
          }
          this.$router.replace('/console')
          // userService.init().then(inited => {
          //   if (inited) {
          //     this.$store.dispatch('refresh', 'user').then(() => {
          //       this.$forceUpdate();
          //       this.$router.replace('/admin')
          //     })
          //   }
          // })
        }
      }).catch((e: any): any => {
        // this.notify({
        //   type: 'danger',
        //   text: e.message
        // });
      });
    }
  }
});
