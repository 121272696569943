
import { defineComponent } from 'vue'
import {mapGetters,mapActions} from "vuex";
import Notification from "@/components/Notification.vue";

export default defineComponent({
  name: 'App',
  components: { Notification },
  beforeMount() {
    // console.log('App before mount: auth ', this.$store.getters.AUTHORIZED)
    if (!this.AUTHORIZED) this.init()
    this.initCompleted().then((completed: boolean) => {
      if (completed && this.AUTHORIZED) this.$router.replace('/console')
      else {
        this.userLoggedOut()
        this.$router.replace('/')
      }
    })
  },
  computed: {
    ...mapGetters([
      'NOTIFICATION',
      'AUTHORIZED'
    ])
  },
  methods: {
    ...mapActions([
        'init',
        'initCompleted',
        'userLoggedOut'
    ])
  }
})

