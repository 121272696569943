import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    show: true,
    "auth-form": true,
    tab: '',
    data: {},
    onSubmit: _ctx.logIn
  }, null, 8, ["onSubmit"]))
}